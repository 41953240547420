import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from '../../../context/LanguageContext'
import ContentPreview from '../../ContentPreview'
import GridContainer from '../../GridContainer'
import RenderIf from '../../RenderIf'
import { Node__Program, Query, Paragraph__Programs_Listing } from '../../../types/generated'

export const ParagraphProgramsListingFields = graphql`
  fragment ParagraphProgramsListingFields on paragraph__programs_listing {
    __typename
    id
    field_title
  }
`

const ParagraphProgramListing = (props: Paragraph__Programs_Listing) => {
  const { field_title: fieldTitle } = props
  const { langcode } = useContext(LanguageContext)
  const data = useStaticQuery<Query>(graphql`
    {
      allNodeProgram(sort: { fields: field_order, order: ASC }) {
        nodes {
          ...NodeProgramFields
        }
      }
    }
  `)

  const allNodeProgramNodes = data?.allNodeProgram?.nodes
  // @todo: remove any type
  const currentNodes = allNodeProgramNodes?.filter(
    (program: Node__Program) => program.langcode === langcode,
  )

  return (
    <section className="max-w-7xl">
      <RenderIf condition={!!fieldTitle}>
        <h1 className="font-bold text-center text-3xl mb-24">{fieldTitle}</h1>
      </RenderIf>
      <GridContainer>
        {currentNodes.map((program: any) => (
          <ContentPreview key={program.id} content={program} />
        ))}
      </GridContainer>
    </section>
  )
}

export default ParagraphProgramListing
